import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    standalone: false
})
export class StarRatingComponent implements OnInit {
	@Input() MAX_NUMBER_OF_STARS = 5;
	@Input() rating = 0;
	@Input() conference?: number;
	@Input() session?: number;
	@Input() title?: string;
	@Input() routing?: boolean;
	@Input() noEvent?: boolean;

	@Output() rate = new EventEmitter();

	constructor(private router: Router) {}

	ngOnInit(): void {}

	private get numberOfFullStars(): number {
		return Math.floor(this.rating);
	}

	private get numberOfEmptyStars(): number {
		return this.MAX_NUMBER_OF_STARS - Math.ceil(this.rating);
	}

	get fullStars(): any[] {
		return Array(this.numberOfFullStars);
	}

	get emptyStars(): any[] {
		return Array(this.numberOfEmptyStars);
	}

	get hasAnHalfStar(): boolean {
		return this.rating % 1 !== 0;
	}

	select(idx: number, type: string): void {
		if (this.numberOfEmptyStars === 5 && type === 'empty') {
			this.rating = idx + 1;
		} else if (type === 'full') {
			this.rating = idx + 1;
		} else {
			this.rating = idx + 1 + this.numberOfFullStars;
		}

		if (this.routing) {
			this.router.navigateByUrl(`/conference/${this.conference}/rating/${this.session}/${this.title}/${this.rating}`).then();
		} else {
			this.rate.emit({ rating: this.rating });
		}
	}
}
