<ng-container *ngIf="controls; else noDelete">
	<td class="checkbox-column px-4 py-2 align-middle">
		<div class="flex h-full w-full items-center" (click)="selectElement($event, mediaObject.id)">
			<input
				type="checkbox"
				[id]="mediaObject.id"
				class="focus:border-indigo-300 pointer-events-none rounded border-gray-600 text-blue-500 outline-none focus:outline-none focus:ring-0 focus:ring-transparent"
			/>
		</div>
	</td>
	<td class="group relative px-4 py-2 align-middle">
		<div class="w-fit-content group flex cursor-pointer items-center" (click)="viewDetail(mediaObject)" title="Details ansehen">
			<div class="mr-1 h-8 w-8 cursor-pointer">
				<ng-container *ngIf="(!mediaObject.directory || mediaObject.directory === false); else isDir">
					<ng-container
						*ngIf="
							mediaObject.mimeType === 'image/jpeg' ||
								mediaObject.mimeType === 'image/jpg' ||
								mediaObject.mimeType === 'image/png' ||
								mediaObject.mimeType === 'image/svg' ||
								mediaObject.mimeType === 'image/gif';
							else noImage
						"
					>
						<img
							class="mx-auto h-8 max-h-full w-8 max-w-full rounded object-cover object-center"
							[src]="mediaObject.url"
							onerror="this.src = './assets/images/placeholder/fallback.png'"
						/>
					</ng-container>
					<ng-template #noImage>
						<div class="flex h-full w-full items-center justify-center text-2xl text-gray-650">
							<fa-icon [icon]="mediaObject.mimeType | mimeTypeFallback"></fa-icon>
						</div>
					</ng-template>
				</ng-container>
				<ng-template #isDir>
					<div class="flex h-full w-full items-center justify-center text-2xl text-gray-650">
						<fa-icon [icon]="faFolder"></fa-icon>
					</div>
				</ng-template>
			</div>
			<p class="cursor-pointer px-2 text-sm leading-none text-gray-700 transition-colors duration-200 ease-in-out group-hover:text-blue-500">
				{{ mediaObject.name }}
			</p>
		</div>
	</td>
	<td class="px-4 py-2 align-middle">
		<ng-container *ngIf="(mediaObject.directory === 0 || !mediaObject.directory)">
			<span class="font-mono text-xs text-gray-700">{{ mediaObject.size | filesize }}</span>
		</ng-container>
	</td>
	<td class="px-4 py-2 align-middle">
		<span class="font-mono text-xs text-gray-700">{{ mediaObject.visibility ? mediaObject.visibility : 'private' }}</span>
	</td>
	<td class="px-4 py-2 align-middle">
		<ng-container *ngIf="(mediaObject.directory === 0 || !mediaObject.directory)">
			<button
				(click)="selectElement($event, mediaObject.id)"
				[id]="mediaObject.id + 'trash'"
				class="appearance-none border-0 bg-transparent px-2 text-sm text-gray-650 hover:text-red-500 focus:outline-none"
				title="Löschen"
			>
				<fa-icon class="pointer-events-none" [icon]="faTrash"></fa-icon>
			</button>
		</ng-container>
	</td>
</ng-container>
<ng-template #noDelete>
	<div class="relative w-full">
		<ng-container *ngIf="(mediaObject.directory === 0 || !mediaObject.directory); else dir">
			<div
				(click)="selectElementDialog($event)"
				class="relative flex w-full transform cursor-pointer flex-row overflow-hidden rounded border-2 border-solid border-gray-500 px-2 py-1 transition-all duration-300 ease-in-out hover:-translate-y-1 hover:shadow-lg"
				[title]="mediaObject.name"
			>
				<div class="relative h-16 w-16">
					<div class="square square--small">
						<div class="square__image square__image--small pointer-events-none absolute overflow-hidden object-cover object-center">
							<ng-container
								*ngIf="
									mediaObject.mimeType === 'image/jpeg' ||
										mediaObject.mimeType === 'image/jpg' ||
										mediaObject.mimeType === 'image/png' ||
										mediaObject.mimeType === 'image/svg' ||
										mediaObject.mimeType === 'image/gif';
									else noImageDelete
								"
							>
								<img
									class="h-full w-full object-contain object-center"
									[src]="mediaObject.url"
									onerror="this.src = './assets/images/placeholder/fallback.png'"
									[title]="mediaObject.name"
								/>
							</ng-container>
							<ng-template #noImageDelete>
								<div class="flex h-full w-full items-center justify-center text-5xl text-gray-500">
									<fa-icon [icon]="mediaObject.mimeType | mimeTypeFallback"></fa-icon>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
				<div class="contents pl-4">
					<div class="flex w-full flex-row items-center">
						<div class="w-1/5 p-2 text-xs text-gray-650">
							<p class="font-mono text-xs leading-none">
								{{ mediaObject.visibility }}
							</p>
							<p class="mt-1 font-mono text-xs leading-none">
								{{ mediaObject.size | filesize }}
							</p>
						</div>
						<div class="w-2/5 px-4 py-2 font-mono text-xs text-gray-650">
							{{ mediaObject.mimeType }}
						</div>
						<div class="w-2/5 overflow-hidden whitespace-nowrap p-2 text-xs text-gray-650">
							<div class="whitespace-nowrap font-mono text-xs">
								{{ mediaObject.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template #dir>
			<div
				(click)="selectElementDialog($event, 'folder')"
				class="relative flex w-full transform cursor-pointer flex-row overflow-hidden rounded border-2 border-solid border-gray-500 px-2 py-1 transition-all duration-300 ease-in-out hover:-translate-y-1 hover:shadow-lg"
				[title]="mediaObject.name"
			>
				<div class="relative h-16 w-16">
					<div class="square square--small">
						<div class="square__image square__image--small pointer-events-none absolute overflow-hidden object-cover object-center">
							<div class="flex h-full w-full items-center justify-center text-5xl text-gray-500">
								<fa-icon [icon]="faFolder"></fa-icon>
							</div>
						</div>
					</div>
				</div>
				<div class="contents pl-4">
					<div class="flex w-full flex-row items-center">
						<div class="w-1/5 p-2 text-xs text-gray-650">
							<p class="leading-none">
								{{ mediaObject.visibility ? mediaObject.visibility : '' }}
							</p>
						</div>
						<div class="w-2/5 px-4 py-2 text-xs text-gray-650">Ordner</div>
						<div class="w-2/5 overflow-hidden whitespace-nowrap p-2 text-xs text-gray-650">
							<div class="whitespace-nowrap">
								{{ mediaObject.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</ng-template>
