import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { VersioningService } from '../../services/versioning.service';

@Component({
    selector: 'app-versioning-banner',
    templateUrl: './versioning-banner.component.html',
    styleUrls: ['./versioning-banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class VersioningBannerComponent implements OnInit, OnDestroy {
	@Input() id: string;
	private readonly element: any;

	constructor(
		private el: ElementRef,
		private versioningService: VersioningService
	) {
		this.element = el.nativeElement;
	}

	ngOnInit(): void {
		if (!this.id) {
			return;
		}

		document.body.appendChild(this.element);

		this.element.addEventListener('click', (el) => {
			if (el.target.className === 'versioning-banner') {
				this.close();
			}
		});

		this.versioningService.addVersionBanner(this);
	}

	ngOnDestroy(): void {
		this.versioningService.removeVersionBanner(this.id);
		this.element.remove();
	}

	open(): void {
		this.element.classList.add('block');
		this.element.classList.remove('hidden');
		document.body.classList.add('versioning-banner-open');
	}

	close(): void {
		this.element.classList.add('hidden');
		this.element.classList.remove('block');
		document.body.classList.remove('versioning-banner-open');
	}
}
