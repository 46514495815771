import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowLeft, faArrowRight, faArrowToLeft, faArrowToRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    standalone: false
})
export class PaginationComponent implements OnInit {
	faNext = faArrowRight;
	faPrevious = faArrowLeft;
	faStart = faArrowToLeft;
	faEnd = faArrowToRight;

	@Input() meta?: any;
	@Input() currentPage: number = 1;

	@Output() pagination = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	paginate(type: string): void {
		console.log('paginate called', this.currentPage);
		console.log(type);
		let newPage: number;
		if ('prev' === type) {
			newPage = this.currentPage - 1;
		} else if ('next' === type) {
			newPage = this.currentPage + 1;
			this.currentPage = newPage;
		} else if ('start' === type) {
			newPage = 1;
		} else {
			newPage = this.meta.last_page;
		}
		console.log(newPage);
		this.currentPage = newPage;
		this.pagination.emit(this.currentPage);
	}
}
