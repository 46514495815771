import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-add-subscription-dialog',
    templateUrl: './add-subscription-dialog.component.html',
    styleUrls: ['./add-subscription-dialog.component.css'],
    standalone: false
})
export class AddSubscriptionDialogComponent implements OnInit, OnDestroy {
	private subscription: Subscription;

	public id: number;
	public title: string;
	public folderGroupsData: any;
	public partner?: number;

	public form: UntypedFormGroup;
	public isValidFormSubmitted = null;
	public error: any;

	public test = [1, 2, 3];

	constructor(
		public dialogRef: MatDialogRef<AddSubscriptionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private fb: UntypedFormBuilder,
		private errorHandler: ErrorHandlerService
	) {
		this.id = data.id;
		this.title = data.type;
		this.partner = data.partner ? data.partner : '';
	}

	ngOnInit(): void {
		this.initData();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initData(): void {
		this.subscription = this.apiService.getData('subscription/plans/select?include=partner').subscribe(
			(rest) => {
				rest.data.forEach((el) => {
					el.itemName = `${el.id} - ${el.name}`;
				});
				this.folderGroupsData = rest.data;
				this.initForm();
			},
			(err: HttpErrorResponse) => {
				this.errorHandler.errorHandler(err, 'Get subscription plans');
			}
		);
	}

	initForm(): void {
		this.form = this.fb.group({
			folderGroups: ['', []],
			expiresAt: ['', []],
		});
	}

	submit(): void {
		this.isValidFormSubmitted = false;

		const folderGroup = this.folderGroupsData.find((obj) => {
			return obj.id === this.form.value.folderGroups[0];
		});

		this.form.value.folderGroups = [folderGroup];

		if (this.form.value.folderGroups && this.form.value.folderGroups[0] && folderGroup) {
			this.onSave(this.form.value);
		}
	}

	onSave(data: any): void {
		this.dialogRef.close(data);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	get folderGroups(): any {
		return this.form.get('folderGroups');
	}
}
