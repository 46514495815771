import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscriptions } from '../../interfaces/subscriptions';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.css'],
    standalone: false
})
export class UserDialogComponent implements OnInit {
	public subscriptions?: Array<Subscriptions>;

	constructor(
		public dialogRef: MatDialogRef<UserDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.subscriptions = data.subscriptions;
	}

	ngOnInit(): void {}

	onClose(): void {
		this.dialogRef.close();
	}
}
