import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
    standalone: false
})
export class FileUploadComponent {
	@Input() progress;
	@Input() size?: number;
	@Input() format?: string;
	@Input() showNote?: boolean = false;
	// tslint:disable-next-line:ban-types
	onChange: Function;
	public file: FileList | null = null;
	public fileArr: any;

	public error: boolean;

	@HostListener('change', ['$event.target.files']) emitFiles(event: FileList): any {
		this.error = false;
		const file = event;

		this.fileArr = [];
		Array.from(file).forEach((item) => {
			this.fileArr.push(item.name);
		});

		this.onChange(file);
		this.file = file;
	}

	constructor(private host: ElementRef<HTMLInputElement>) {}

	writeValue(value: null): void {
		// clear file input
		this.host.nativeElement.value = '';
		this.file = null;
	}

	// tslint:disable-next-line:ban-types
	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	// tslint:disable-next-line:ban-types
	registerOnTouched(fn: Function): void {}
}
