import { Component, OnInit } from '@angular/core';
import { faSquareTerminal } from '@fortawesome/pro-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { TerminalDialogComponent } from '../../dialogs/terminal-dialog/terminal-dialog.component';

@Component({
    selector: 'app-terminal',
    templateUrl: './terminal.component.html',
    styleUrls: ['./terminal.component.scss'],
    standalone: false
})
export class TerminalComponent implements OnInit {
	faTerminal = faSquareTerminal;

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	open(): void {
		const dialogRef = this.dialog.open(TerminalDialogComponent, {
			maxWidth: '480px',
			width: '100%',
			panelClass: 'terminal-dialog',
		});
	}
}
