import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { forkJoin, Subscription } from 'rxjs';
import { faArrowLeft, faArrowRight, faBug, faQuestionCircle, faSpinner, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Partner } from '../../interfaces/partner';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { requiredFileType } from '../../validators/file-type';
import { environment } from '../../../../environments/environment';
import { SessionQuery } from '../../session/state';
import { HelperService } from '../../services/helper.service';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
    selector: 'app-upload-image-dialog',
    templateUrl: './upload-image-dialog.component.html',
    styleUrls: ['./upload-image-dialog.component.css'],
    standalone: false
})
export class UploadImageDialogComponent implements OnInit, OnDestroy {
	private subscription: Subscription;
	private subscriptionFolder: Subscription;
	private subscriptionData: Subscription;

	private apiUrl = environment.API_URL;

	public type: string;
	public title: string;

	public loading: boolean;
	public data: Array<any>;
	public meta: any;
	public links: any;

	public form: UntypedFormGroup;
	public isValidFormSubmitted = null;
	public error: any;

	public size: number;
	public format: string;
	public folderId: number;

	public isUploading: boolean;

	faNext = faArrowRight;
	faPrevious = faArrowLeft;
	faHelp = faQuestionCircle;
	faSuccess = faThumbsUp;
	faBug = faBug;
	faSpinner = faSpinner;

	public allowSelectFolder: boolean;

	public partner: Array<Partner>;
	public folders: Array<any>;
	public progress = 0;
	public width?: number = 0;
	public height?: number = 0;
	public quality?: number = 90;
	public multiUpload?: boolean = false;
	public mimeType?: string;

	constructor(
		public dialogRef: MatDialogRef<UploadImageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private errorHandler: ErrorHandlerService,
		private fb: UntypedFormBuilder,
		private http: HttpClient,
		private sessionQuery: SessionQuery,
		private helperService: HelperService,
		private imageCompress: NgxImageCompressService
	) {
		this.type = data.type ? data.size : null;
		this.size = data.size ? data.size : null;
		this.format = data.format ? data.format : null;
		this.allowSelectFolder = data.allowSelectFolder ? data.allowSelectFolder : false;
		this.folderId = data.folder ? data.folder : null;
		this.height = data.height ? data.height : 0;
		this.width = data.width ? data.width : 0;
		this.multiUpload = data.multiUpload ? data.multiUpload : false;
		this.quality = data.quality ? data.quality : 75;
		this.mimeType = data.mimeType ? data.mimeType : '';
	}

	ngOnInit(): void {
		if (this.checkAccess(['Super Admin', 'Admin'])) {
			this.initPartner();
		} else {
			this.initForm();
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.subscriptionFolder) {
			this.subscriptionFolder.unsubscribe();
		}
		if (this.subscriptionData) {
			this.subscriptionData.unsubscribe();
		}
	}

	// Get partner
	initPartner(): void {
		this.initForm();
		const partner = this.apiService.getData(`partners/select`);
		const folders = this.apiService.getData('folders/select');
		this.subscriptionData = forkJoin([partner, folders]).subscribe(
			(res) => {
				this.partner = res[0].data;
				this.folders = res[1].data;
			},
			(err) => {
				this.errorHandler.errorHandler(err, 'Get Partners and Folders');
			},
			() => {}
		);
	}

	initForm(): void {
		this.form = this.fb.group({
			image: new UntypedFormControl(null, [
				Validators.required,
				requiredFileType(['png', 'jpg', 'jpeg', 'pdf', 'webp', 'doc', 'docx', 'docm', 'xlsm', 'xlsx', 'svg', 'gif', 'ppt', 'epub', 'zip']),
			]),
			visibility: ['public', [Validators.required]],
			name: ['', []],
			folder: ['', this.allowSelectFolder ? Validators.required : []],
			partner: ['', []],
		});

		if (this.form && this.folderId) {
			this.form.patchValue({
				folder: this.folderId,
			});
		}
	}

	submitForm(): void {
		if (this.multiUpload || this.mimeType === 'zip' || this.mimeType === 'pdf' || this.mimeType === 'epub') {
			this.uploadImage();
		} else {
			const image = this.form.get('image').value;
			const reader = new FileReader();
			reader.onloadend = () => {
				const dataUrl = reader.result;
				this.imageCompress
					.compressFile(<string>dataUrl, 0, 100, this.quality, this.width, this.height)
					.then((compressedImage) => {
						this.generateBlob(compressedImage, image[0].name);
					})
					.catch((err) => {
						this.errorHandler.openSnackBar(err);
					});
			};
			reader.readAsDataURL(image[0]);
		}
	}

	generateBlob(dataUrl: any, name: string): void {
		const blob = this.helperService.dataURItoBlob(dataUrl);
		this.uploadImage(blob, name);
	}

	uploadImage(blob?: any, name?: string): void {
		this.isValidFormSubmitted = false;
		if (this.form.invalid) {
			return;
		}

		this.isUploading = true;

		const formData = new FormData();
		// formData.append('attachments', this.form.get('image').value);

		if (this.multiUpload || this.mimeType === 'zip' || this.mimeType === 'pdf' || this.mimeType === 'epub') {
			Array.from(this.form.get('image').value).forEach((item: any) => {
				formData.append('attachments[]', item);
			});
		} else {
			formData.append('attachments[]', blob, name);
		}
		formData.append('visibility', this.form.get('visibility').value);

		if (this.form.get('partner').value) {
			formData.append('partner', this.form.get('partner').value);
		}

		if (this.form.get('name').value) {
			formData.append('name', this.form.get('name').value);
		}

		if (this.form.get('folder').value) {
			formData.append('parent', this.form.get('folder').value);
		}

		this.subscription = this.http
			.post(`${this.apiUrl}admin/attachments`, formData, {
				reportProgress: true,
				observe: 'events',
			})
			.subscribe(
				(event) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.progress = Math.round((100 * event.loaded) / event.total);
					}

					if (event.type === HttpEventType.Response) {
					}

					// @ts-ignore
					if (event.status === 201 && event.body?.data[0]?.id) {
						if (!this.allowSelectFolder) {
							// @ts-ignore
							this.onUploaded(event.body.data[0]);
							this.isUploading = false;
						} else {
							// @ts-ignore
							this.onUploaded(this.form.get('folder').value);
							this.isUploading = false;
						}
					}
				},
				(err) => {
					this.errorHandler.errorHandler(err, 'Upload File');
					this.error = err.error;
					this.isUploading = false;
				},
				() => {
					this.isValidFormSubmitted = true;
					this.form.reset();
					this.form.patchValue({
						visibility: 'public',
					});
					this.isUploading = false;
				}
			);
	}

	get visibility(): any {
		return this.form.get('visibility');
	}

	onUploaded(data: any): void {
		this.dialogRef.close(data);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	checkAccess(rolesArr: Array<string>): boolean {
		const roles = this.sessionQuery.hasRoles();

		let hasRole = false;
		if (roles && roles.length > 0) {
			hasRole = rolesArr.some((item) => roles.includes(item));
		}

		return hasRole;
	}
}
