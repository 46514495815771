import { Component, Input, OnInit } from '@angular/core';
import { Subnav } from '../../interfaces/subnav';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    standalone: false
})
export class ShellComponent implements OnInit {
	@Input() title: string;
	@Input() subnav?: Array<Subnav>;

	public live = environment.live;

	constructor() {}

	ngOnInit(): void {}
}
