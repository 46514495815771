import { Component, Input, OnInit } from '@angular/core';
import { Subnav } from '../../interfaces/subnav';

@Component({
    selector: 'app-subnav',
    templateUrl: './subnav.component.html',
    styleUrls: ['./subnav.component.css'],
    standalone: false
})
export class SubnavComponent implements OnInit {
	@Input() subnav?: Array<Subnav>;

	constructor() {}

	ngOnInit(): void {}
}
