import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { faArrowLeft, faArrowRight, faBug, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { Partner } from '../../interfaces/partner';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SessionQuery } from '../../session/state';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-add-folder-dialog',
    templateUrl: './add-folder-dialog.component.html',
    styleUrls: ['./add-folder-dialog.component.css'],
    standalone: false
})
export class AddFolderDialogComponent implements OnInit, OnDestroy {
	private subscription: Subscription;
	private subscriptionSubmit: Subscription;
	private apiUrl = environment.API_URL;

	public type: number;
	public title: string;

	public loading: boolean;
	public data: Array<any>;
	public meta: any;
	public links: any;

	public parent: any;

	public form: UntypedFormGroup;
	public isValidFormSubmitted = null;
	public error: any;

	public size: number;
	public format: string;

	faNext = faArrowRight;
	faPrevious = faArrowLeft;
	faBug = faBug;
	faSuccess = faThumbsUp;

	@ViewChild('search') search: ElementRef;

	public partner: Array<Partner>;
	public progress = 0;

	constructor(
		public dialogRef: MatDialogRef<AddFolderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private errorHandler: ErrorHandlerService,
		private fb: UntypedFormBuilder,
		private http: HttpClient,
		private sessionQuery: SessionQuery
	) {
		this.parent = data.parent ? data.parent : null;
	}

	ngOnInit(): void {
		if (this.checkAccess(['Super Admin', 'Admin'])) {
			this.initPartner();
		} else {
			this.initForm();
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.subscriptionSubmit) {
			this.subscriptionSubmit.unsubscribe();
		}
	}

	// Get partner
	initPartner(): void {
		this.subscription = this.apiService.getData(`partners/select`).subscribe(
			(res) => {
				this.partner = res.data;
				this.initForm();
			},
			(err) => {
				this.errorHandler.errorHandler(err, 'Get Partners');
			},
			() => {}
		);
	}

	initForm(): void {
		this.form = this.fb.group({
			name: ['', [Validators.required]],
			partner: ['', []],
		});
	}

	submitForm(): void {
		this.isValidFormSubmitted = false;
		if (this.form.invalid) {
			return;
		}

		const formData = new FormData();
		formData.append('name', this.form.get('name').value);
		formData.append('directory', '1');

		if (this.form.get('partner').value) {
			formData.append('partner', this.form.get('partner').value);
		}

		if (this.parent) {
			formData.append('parent', this.parent.toString());
		}

		this.subscriptionSubmit = this.http
			.post(`${this.apiUrl}admin/attachments`, formData, {
				reportProgress: true,
				observe: 'events',
			})
			.subscribe(
				(event) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.progress = Math.round((100 * event.loaded) / event.total);
					}

					if (event.type === HttpEventType.Response) {
					}

					// @ts-ignore
					if (event.status === 201 && event.body?.data[0]?.id) {
						// @ts-ignore
						this.onAdded();
					}
				},
				(err) => {
					this.errorHandler.errorHandler(err, 'Add folder');
					this.error = err.error;
				},
				() => {
					this.isValidFormSubmitted = true;
					this.form.reset();
				}
			);
	}

	get name(): any {
		return this.form.get('name');
	}

	onAdded(): void {
		this.dialogRef.close(true);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	checkAccess(rolesArr: Array<string>): boolean {
		const roles = this.sessionQuery.hasRoles();

		let hasRole = false;
		if (roles && roles.length > 0) {
			hasRole = rolesArr.some((item) => roles.includes(item));
		}

		return hasRole;
	}
}
