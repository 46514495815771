import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-select-image-dialog',
    templateUrl: './select-image-dialog.component.html',
    styleUrls: ['./select-image-dialog.component.css'],
    standalone: false
})
export class SelectImageDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	private subscription: Subscription;
	private subscriptionSearch: Subscription;

	public id: number;
	public title: string;

	public loading: boolean;
	public data: Array<any>;
	public meta: any;
	public links: any;
	public isFolder: boolean;
	public folderId: number;
	public folderName: string;
	public allowFolderSelection: boolean;

	faNext = faArrowRight;
	faPrevious = faArrowLeft;

	@ViewChild('search') search: ElementRef;

	private mimeType: string;
	private include = '';

	constructor(
		public dialogRef: MatDialogRef<SelectImageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private apiService: ApiService
	) {
		this.id = data.id;
		this.title = data.type;
		if (data.mimeType) {
			this.mimeType = data.mimeType;
			this.include = `&type=${this.mimeType}`;
		}

		if (data.allowSelectFolder) {
			this.allowFolderSelection = data.allowSelectFolder;
		}
	}

	ngOnInit(): void {
		this.initData('?page=1');
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.subscriptionSearch) {
			this.subscriptionSearch.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		this.searchData();
	}

	initData(page: string, isFolder?: boolean): void {
		this.loading = true;

		if (this.subscription) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.apiService.getData(`attachments${page}${this.include}&sort=-createdAt`).subscribe({
			next: (rest) => {
				this.data = rest.data;
				this.meta = rest.meta;
				this.links = rest.links;
				if (isFolder) {
					this.isFolder = true;
				}
				setTimeout(() => {
					this.loading = false;
				}, 1500);
			},
			error: () => {
				this.loading = false;
			},
		});
	}

	// Search data
	searchData(): void {
		this.subscriptionSearch = fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				filter(Boolean),
				debounceTime(250),
				distinctUntilChanged(),
				tap(() => {
					this.initData(`?page=1&${this.include}&search=${this.search.nativeElement.value}`);
				})
			)
			.subscribe();
	}

	// Get new data by page
	pagination(type: string): void {
		if ('prev' === type) {
			const newPage = this.meta.current_page - 1;
			if (newPage < 1) {
				return;
			}
			if (this.search.nativeElement.value) {
				this.initData(`?page=${newPage}&search=${this.search.nativeElement.value}${this.include}`);
			} else {
				this.initData(`?page=${newPage}${this.include}}`);
			}
		} else {
			const newPage = this.meta.current_page + 1;
			if (newPage >= this.meta.total) {
				return;
			}
			if (this.search.nativeElement.value) {
				this.initData(`?page=${newPage}&search=${this.search.nativeElement.value}${this.include}`);
			} else {
				this.initData(`?page=${newPage}${this.include}}`);
			}
		}
	}

	goBack(parent?: number): void {
		if (parent) {
			this.initData(`?page=1&folder=${parent}`, true);
			this.folderId = parent;
		} else {
			this.initData(`?page=1`);
			this.isFolder = false;
			this.folderId = null;
			this.folderName = '';
		}
	}

	selectFile(event: any): any {
		if (event.type === 'folder') {
			this.initData(`?page=1&folder=${event.id}`, true);
			this.folderId = event.id;
			this.folderName = event.name;
		} else {
			this.onSave(event);
			this.isFolder = false;
			this.folderId = null;
		}
	}

	selectFolder(): void {
		this.onSave({ id: this.folderId, name: this.folderName });
	}

	onSave(data: any): void {
		this.dialogRef.close(data);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
